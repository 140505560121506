import { useRouter } from 'next/router';
import { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import CardSubMenu from 'src/components/cards/card-sub-menu';
import { SHOW_COMMEMORATE } from 'src/constants';
import {
  DATALAYER_TYPE,
  sendDataLayerGlobal
} from 'src/services/member-auth/datalayer/send-datalayer';
const MenuExpandMore = ({
  data,
  moreMenu
}) => {
  const router = useRouter();
  // ---------- expand sub-menu ----------
  const [subToggleMoreMenu, setSubToggleMoreMenu] = useState({
    status: false,
    key: null
  });

  return (
    <div className='header-menus' >
    <nav className='nav-dropdown-desktop nav-more'>
      {/* ------------------- expand-more-menu ------------------- */}
      <div className={`expand-more-menu ${moreMenu && '--hide'}`}>
          <ul className='nav-width' style={{ backgroundColor: SHOW_COMMEMORATE === 'true' ? 'gray' : '#000060' }}>
            {
              data.map((item, index) => <li key={index}
              className={`${router.asPath === `/${item.link}` ? 'active' : ''} position-relative`}
              onMouseLeave={() => setSubToggleMoreMenu({ key: null, status: false })}>
                {
                  item.sub.length > 0
                    ? <>
                      <a href={`/${item.link}`}
                        onMouseOver={() => {
                          setSubToggleMoreMenu({ status: true, key: index });
                        }}
                        key={index}
                        //! DATALAYER
                        onClick={() =>
                          sendDataLayerGlobal({
                            type: DATALAYER_TYPE.TRACK_POSITION,
                            router: router?.pathname,
                            position: 'ใน:menubar',
                            section: 'header',
                            data: {
                              title: `${item?.nameTh}`,
                              heading: `${item?.nameTh}`
                            }
                          })
                        }
                      >
                        <span>
                          {item.nameTh}
                        </span>
                        {item.sub.length > 0 && <FaAngleDown />}
                      </a>
                      {(subToggleMoreMenu.status && subToggleMoreMenu.key === index && item.sub.length > 0) &&
                             <ul className='nav-sub-menu custom-nav-top'
                                style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}
                                onMouseLeave={() => setSubToggleMoreMenu({ key: null, status: false })} >
                                <CardSubMenu data={item.sub} menu={item} />
                            </ul>
                          }
                            </>
                    : <a href={`/${item.link}`}>
                      <span>
                        {item.nameTh}
                      </span>
                    </a>
                }
              </li>)
            }
          </ul>
      </div>
    </nav>
    </div>
  );
};

export default MenuExpandMore;
